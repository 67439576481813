.promot-calendar {}

.promot-calendar .event-item {
    border-radius: 100px;
    border-width: 0 !important;
    /* padding-left: 12px;
    padding-right: 12px; */
    font-size: 14px;
    line-height: 160%;
    font-family: 'Inter-SemiBold';
    color: white;
    min-width: 28px;
}

.promot-calendar .rct-hl-odd,
.promot-calendar .rct-hl-even {
    background: inherit !important;
    border-bottom: 0 !important;
}

.promot-calendar .rct-vl {
    border-left-color: #E2E8F0 !important;
    background-color: white !important;
}

.promot-calendar .rct-day-0,
.promot-calendar .rct-day-6 {
    background-color: #F1F5F9 !important;
}

.promot-calendar .rct-header-root {
    border-width: 0 !important;
    background-color: white !important;
}

.promot-calendar .rct-calendar-header {
    border-width: 0 !important;
}

.promot-calendar .rct-dateHeader {
    border-width: 0 !important;
    background-color: white !important;
    color: var(--color-text2) !important;
    font-size: 14px !important;
    font-family: 'Inter-SemiBold' !important;
    width: auto !important;
}

.promot-calendar .rct-scroll {
    overflow-x: hidden !important;
}

.promot-calendar .rct-outer,
.promot-calendar .rct-scroll,
.promot-calendar .rct-vl,
.promot-calendar .rct-sidebar,
.promot-calendar .rct-sidebar-row,
.promot-calendar .rct-horizontal-lines .rct-hl-even {
    min-height: calc(100vh - 320px);
}

.promot-calendar .rct-sidebar {
    border-right: 1px solid #F1F5F9;
}

.promot-calendar .rct-sidebar-row {
    border-bottom-width: 0 !important;
}

.promot-calendar .sidebar-header {
    border-bottom: 1px solid #F1F5F9;
    border-right: 1px solid #F1F5F9;
    align-items: flex-start;

    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.2px;
    color: #64748B;
    font-family: 'Inter-Bold';
}

.promot-calendar .rct-sidebar-row {
    overflow-y: auto !important;
}

.promot-calendar .custom-group {
    padding-left: 20px;
    padding-right: 10px;
    font-size: 16px;
    line-height: 160% !important;
    font-family: 'Inter-SemiBold';
    color: #0F172A;
    text-align: left;
    white-space: break-spaces;
}

.promot-calendar .custom-group li {
    margin-top: 20px;
}

.promot-calendar .event-item .rct-item-content {
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    padding-left: 5px;
    padding-right: 5px;
}