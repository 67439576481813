@font-face {
  font-family: 'Inter-Black';
  src: url(../fonts/Inter-Black.ttf) format('truetype');
}

@font-face {
  font-family: 'Inter-BlackItalic';
  src: url(../fonts/Inter-BlackItalic.ttf) format('truetype');
}

@font-face {
  font-family: 'Inter-Bold';
  src: url(../fonts/Inter-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Inter-BoldItalic';
  src: url(../fonts/Inter-BoldItalic.ttf) format('truetype');
}

@font-face {
  font-family: 'Inter-ExtraBold';
  src: url(../fonts/Inter-ExtraBold.ttf) format('truetype');
}

@font-face {
  font-family: 'Inter-ExtraBoldItalic';
  src: url(../fonts/Inter-ExtraBoldItalic.ttf) format('truetype');
}

@font-face {
  font-family: 'Inter-Light';
  src: url(../fonts/Inter-LightBETA.ttf) format('truetype');
}

@font-face {
  font-family: 'Inter-LightItalic';
  src: url(../fonts/Inter-LightItalicBETA.ttf) format('truetype');
}

@font-face {
  font-family: 'Inter-Medium';
  src: url(../fonts/Inter-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'Inter-MediumItalic';
  src: url(../fonts/Inter-MediumItalic.ttf) format('truetype');
}

@font-face {
  font-family: 'Inter-Regular';
  src: url(../fonts/Inter-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Inter-SemiBold';
  src: url(../fonts/Inter-SemiBold.ttf) format('truetype');
}

@font-face {
  font-family: 'Inter-Thin';
  src: url(../fonts/Inter-ThinBETA.ttf) format('truetype');
}


:root {
  --color-black: #000;
  --color-white: #fff;
  --color-red: #f00;
  --color-blue1: #2563EB;
  --color-blue2: #3ac2d1;
  --color-gray1: #FAFCFE;
  --color-gray2: #F8FAFC;
  --color-gray3: #F1F5F9;
  --color-gray4: #E2E8F0;
  --color-text1: #0F172A;
  --color-text2: #64748B;
  --color-text3: #3ac2d1;
  --color-text4: #94A3B8;
}

body {
  font-family: 'Inter-Regular', sans-serif;
}

option {
  color: black;
}


.flex_1 {
  flex: 1;
}

.flex_wrap {
  flex-wrap: wrap;
}

.pos_relative {
  position: relative;
}

.row_center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.flex_between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.center-align {
  display: flex;
  justify-content: center;
}

.align-middle {
  display: flex;
  justify-content: center;
  align-items: center;
}

.align-col-middle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.align-col-start {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.align-start {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
}

.align-row-start {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.align-end {
  display: flex;
  justify-content: flex-end;
}

.row-start {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.h100 {
  height: 100%;
}

.w100 {
  width: 100%;
}

.pagination {
  display: inline-block;
  padding-left: 0;
  margin: 20px 0;
  border-radius: 4px;
}

.pagination>li {
  display: inline;
}

.pagination>li:first-child>a, .pagination>li:first-child>span {
  margin-left: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.pagination>li>a, .pagination>li>span {
  position: relative;
  float: left;
  padding: 6px 12px;
  margin-left: -1px;
  line-height: 1.42857143;
  color: #337ab7;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #ddd;
}

.pagination>li:last-child>a, .pagination>li:last-child>span {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.pagination>li>a:focus, .pagination>li>a:hover, .pagination>li>span:focus, .pagination>li>span:hover {
  z-index: 2;
  color: #23527c;
  background-color: #eee;
  border-color: #ddd;
}

.custom-form {
  border-radius: 16px !important;
}

.custom-form header {
  font-size: 18px;
  line-height: 140%;
  color: var(--color-text1);
  font-family: 'Inter-bold';
  padding: 24px;
  border-bottom: 1px solid var(--color-gray3);
}

.custom-form .chakra-modal__close-btn {
  top: 18px;
  right: 24px;
  width: 40px;
  height: 40px;
  border-radius: 8px;
  background-color: var(--color-gray2);
  outline: none !important;
}

.custom-form .chakra-modal__body {
  padding: 24px;
}

.custom-form footer {
  padding: 24px;
  border-top: 1px solid var(--color-gray3);
  border-radius: 12px;
}

.custom-form footer button {
  flex: 1;
  height: 56px;
  border-radius: 12px;
  font-size: 16px;
  line-height: 140%;
  font-family: 'Inter-SemiBold';
}

.custom-form footer button.cancel {
  flex: 1;
  border: 1px solid var(--color-gray4);
  background-color: white;
  color: var(--color-text1);
}

.custom-form footer button.ok {
  flex: 1;
  background-color: var(--color-blue2);
  color: white;
}

.custom-form .label {
  font-size: 14px;
  line-height: 160%;
  font-family: 'Inter-SemiBold';
  color: var(--color-text1);
  margin: 20px 0px 14px 0px;
}

.custom-form .label-data {
  font-size: 14px;
  line-height: 160%;
  font-family: 'Inter-SemiBold';
  color: var(--color-text1);
  margin: 0px 0px 6px 0px;
}

.custom-form p {
  font-size: 16px;
  font-family: 'Inter-Medium';
  color: var(--color-text2);
  margin: 0px;
}

.custom-form select,
.custom-form .chakra-input,
.custom-form textarea,
.custom-form .vendor-select,
.custom-form .react-datepicker-wrapper {
  border: 1px solid var(--color-gray4);
  border-radius: 12px;
  outline: none !important;
  box-shadow: none !important;
  font-size: 16px;
  line-height: 150%;
  font-family: 'Inter-SemiBold';
  color: var(--color-text1);
}

.custom-form select::placeholder,
.custom-form .chakra-input::placeholder,
.custom-form textarea::placeholder,
.custom-form .date-picker input::placeholder {
  font-family: 'Inter-Regular';
  color: var(--color-text4);
}

.custom-form select,
.custom-form .chakra-input,
.custom-form .date-picker {
  padding: 8px 16px;
  height: 56px;
}

.custom-form .vendor-select {
  display: flex;
  justify-content: center;
}

.custom-form .date-picker,
.custom-form .vendor-select div {
  border-width: 0;
  border-radius: 12px;
}

.custom-form .vendor-select div:focus,
.custom-form .vendor-select div[data-focus] {
  border-width: 0 !important;
  box-shadow: none !important;
}

.custom-form .vendor-select #react-select-5-listbox {
  display: block !important;
}

.custom-form .vendor-select .vendor-select__menu {
  border: 1px solid var(--color-gray4);
  border-radius: 8px;
  z-index: 2;
}

.custom-form .date-picker,
.custom-form .chakra-checkbox .chakra-checkbox__label {
  outline: none !important;
  box-shadow: none !important;
  font-size: 16px;
  line-height: 150%;
  font-family: 'Inter-SemiBold';
  color: var(--color-text1);
}

.custom-form .date-picker:focus {
  outline: none !important;
  border-width: 0 !important;
  box-shadow: none !important;
}

.custom-form .noti-form-add {
  background-color: var(--color-blue2);
  color: white;
  border-radius: 12px;
  font-size: 16px;
  line-height: 140%;
  font-family: 'Inter-SemiBold';
}

.app-main-btn {
  background-color: var(--color-blue2) !important;
  color: white !important;
  border-radius: 12px !important;
  font-size: 16px !important;
  line-height: 140% !important;
  font-family: 'Inter-SemiBold' !important;
}

.app-main-btn.cancel {
  background-color: var(--color-red) !important;
}

.custom-table thead {
  border-width: 0px;
  padding: 22px;
  height: 64px;
}

.custom-table thead th {
  text-transform: none;
  font-size: 16px;
  line-height: 150%;
  font-family: 'Inter-Medium';
  font-weight: 500;
}

.custom-table thead th:first-child {
  border-left-style: solid;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}

.custom-table thead th:last-child {
  border-right-style: solid;
  border-bottom-right-radius: 12px;
  border-top-right-radius: 12px;
}

.custom-table td {
  border-bottom: 1px solid var(--color-gray3);
  font-size: 16px;
  line-height: 150%;
  font-family: 'Inter-SemiBold';
}

.custom-table td p {
  font-size: 16px;
  line-height: 150%;
  font-family: 'Inter-SemiBold';
}

.custom-table-pagination {}

.custom-table-pagination .page-btn a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  margin-left: 4px;
  margin-right: 4px;
  border-radius: 12px;
  border: 1px solid var(--color-gray4);
  font-size: 18px;
  line-height: 140%;
  font-family: 'Inter-Bold';
  color: var(--color-text4);
}

.custom-table-pagination .first-btn a {
  margin-right: 20px;
  border-top-left-radius: 12px !important;
  border-bottom-left-radius: 12px !important;
}

.custom-table-pagination .last-btn a {
  margin-left: 20px;
  border-top-right-radius: 12px !important;
  border-bottom-right-radius: 12px !important;
}


@media (max-width: 768px) {

  .custom-table-pagination .page-btn a {
    width: 32px;
    height: 32px;
    margin-left: 2px;
    margin-right: 2px;
    border-radius: 8px;
    font-size: 15px;
  }
  
  .custom-table-pagination .first-btn a {
    margin-right: 12px;
    border-top-left-radius: 8px !important;
    border-bottom-left-radius: 8px !important;
  }
  
  .custom-table-pagination .last-btn a {
    margin-left: 12px;
    border-top-right-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
  }
}

@media (max-width: 576px) {
  .custom-table-pagination .first-btn a {
    margin-right: 2px;
  }
  
  .custom-table-pagination .last-btn a {
    margin-left: 2px;
  }
}

/* .pagination>li.active>a {
  z-index: 2;
  color: var(--color-blue1);
  background-color: #fff;
  border-color: var(--color-gray4);
}

.pagination>li>a:focus, .pagination>li>a:hover, .pagination>li>span:focus, .pagination>li>span:hover {
  z-index: 2;
  color: var(--color-text4);
  background-color: #fff;
  border-color: var(--color-gray4);
} */

.pagination>li.active>a {
  z-index: 2;
  color: #23527c;
  background-color: #eee;
  border-color: #ddd;
}

.custom-table-pagesize select {
  min-width: 120px !important;
  height: 48px !important;
  border-radius: 12px !important;
  border: 1px solid var(--color-gray4) !important;
  padding: 12px 16px !important;
  font-size: 14px;
  line-height: 160%;
  font-family: 'Inter-Medium';
  color: var(--color-text4);
}

.custom-table-pagesize select:focus {
  outline: none !important;
  box-shadow: none !important;
}

.custom-table-pagesize svg {
  color: var(--color-text4) !important;
}

.chakra-popover__content {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px !important;
}