.create-noti-modal {}

.create-noti-modal .body h3 {
    font-size: 22px;
    font-weight: bold;
}

.create-noti-modal .body p {
    font-size: 16px;
    font-weight: 600;
    margin:  0px;
}
