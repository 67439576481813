.sidebar-category-menu .chakra-accordion__button:focus{
    outline: none !important;
    box-shadow: none !important;
    background-color: transparent !important;
}

.sidebar-category-menu .chakra-accordion__button{
    outline: none !important;
    box-shadow: none !important;
    width: 100%;
}

.sidebar-category-menu .chakra-accordion__item {
    border-top-width: 0 !important;
    border-bottom-width: 0 !important;
}

.sidebar-link svg.active path{
    stroke: var(--color-text3);
}

.sidebar-link svg.inactive path{
    stroke: var(--color-text2);
}