.promo-config .label {
    margin-top: 16px;
    margin-bottom: 5px;
}

.promo-config h3 {
    font-size: 24px;
    line-height: 32px;
    font-weight: bold;
    color: #000;
}